import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

import routes from "routes";
import footerRoutes from "footer.routes";

// import Contact from "../Author/sections/Contact";
import bgContactus from "assets/images/bg-contactus.jpg";
import InvoiceGenerator from "./invoice";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button } from "@mui/material";

function Invoice() {
    const navigate = useNavigate();
    useEffect(() => {
        if (localStorage?.getItem("incred")) {
            // navigate("/invoice-generate");
        } else {
            navigate("/authentication/login");
        }
    }, [])

    return (
        <>
            <DefaultNavbar
                routes={routes}
                transparent
                light
            />
            <MKBox
                minHeight="50vh"
                width="100%"
                sx={{
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${bgContactus})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid
                        container
                        item
                        xs={12}
                        lg={8}
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        sx={{ mx: "auto", textAlign: "center" }}
                    >
                        <MKTypography variant="h2" color="white" mt={8} mb={1}>
                            Generate Invoice.
                        </MKTypography>
                        <MKBox display="flex" justifyContent="center" alignItems="center">
                            <Button variant="contained" color="primary" sx={{ mt: 2, width: '100%', color: '#000' }}
                                onClick={() => { localStorage?.clear(); navigate("/"); }}>
                                Logout
                            </Button>
                        </MKBox>
                    </Grid>
                </Container>
            </MKBox>
            <Card
                sx={{
                    p: 2,
                    mx: { xs: 2, lg: 3 },
                    mt: -8,
                    mb: 4,
                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
                }}
            >
                <InvoiceGenerator />
            </Card >

            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </>
    );
}

export default Invoice;