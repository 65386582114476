import React, { useState } from "react";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { Table, TableBody, TableRow, TableCell, Typography, TextField, Button, Select, MenuItem, InputLabel, FormControl, Paper, Grid, TableContainer } from "@mui/material";

const InvoiceGenerator = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        date: new Date().toISOString().split("T")[0],
        month: "",
        classes: [],
    });
    const [showInvoice, setShowInvoice] = useState(false);

    const classLevels = ["Beginners", "Intermediate", "Advance", "Played Game"];

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleClassChange = (index, field, value) => {
        const updatedClasses = [...formData.classes];
        updatedClasses[index][field] = value;
        updatedClasses[index].total = updatedClasses[index].fees * updatedClasses[index].numClasses;
        setFormData({ ...formData, classes: updatedClasses });
    };

    const addClass = () => {
        setFormData({
            ...formData,
            classes: [...formData.classes, { classLevel: "", fees: "", numClasses: "", total: 0 }],
        });
    };

    const calculateGrandTotal = () => {
        return formData.classes.reduce((sum, item) => sum + Number(item.total), 0);
    };

    const generatePDF = () => {
        const doc = new jsPDF();

        const invoiceHtml = `
        <div style="max-width: 800px; margin: auto; padding: 20px; border: 1px solid #ddd; border-radius: 10px; font-family: Arial, sans-serif; background: #f9f9f9;">
          <!-- Invoice Header -->
          <div style="text-align: center; font-size: 22px; font-weight: bold; color: #2F4F4F; margin-bottom: 20px;">
            IMCHESS Academy - Invoice
          </div>
      
          <!-- Customer Details -->
          <div style="background: #DCDCDC; color: #000000; padding: 15px; border-radius: 5px; display: flex; justify-content: space-between;">
            <div>
              <p style="margin: 5px 0;"><strong>Name:</strong> ${formData.name}</p>
              <p style="margin: 5px 0;"><strong>Email:</strong> ${formData.email}</p>
            </div>
            <div style="text-align: right;">
              <p style="margin: 5px 0;"><strong>Phone:</strong> ${formData.phone}</p>
              <p style="margin: 5px 0;"><strong>Date:</strong> ${formData.date}</p>
              <p style="margin: 5px 0;"><strong>Month:</strong> ${formData.month}</p>
            </div>
          </div>
      
          <!-- Invoice Table -->
          <table style="width: 100%; border-collapse: collapse; margin-top: 20px; background: white; border-radius: 5px; overflow: hidden;">
            <thead>
              <tr style="background: #2F4F4F; color: white; text-align: left;">
                <th style="border: 1px solid #ddd; padding: 12px;">Class Level</th>
                <th style="border: 1px solid #ddd; padding: 12px; text-align: right;">Fees</th>
                <th style="border: 1px solid #ddd; padding: 12px; text-align: right;">Number of Classes</th>
                <th style="border: 1px solid #ddd; padding: 12px; text-align: right;">Total</th>
              </tr>
            </thead>
            <tbody>
              ${formData.classes
                .map(
                    (cls, index) => `
                    <tr style="background: ${index % 2 === 0 ? '#f5f5f5' : 'white'};">
                      <td style="border: 1px solid #ddd; padding: 12px;">${cls.classLevel}</td>
                      <td style="border: 1px solid #ddd; padding: 12px; text-align: right;">${cls.fees}</td>
                      <td style="border: 1px solid #ddd; padding: 12px; text-align: right;">${cls.numClasses}</td>
                      <td style="border: 1px solid #ddd; padding: 12px; text-align: right;">${cls.total}</td>
                    </tr>
                  `
                )
                .join("")}
            </tbody>
          </table>
      
          <!-- Grand Total -->
          <div style="margin-top: 20px; text-align: right; font-size: 18px; font-weight: bold; color: #333;">
            Grand Total: ${calculateGrandTotal()}
          </div>
      
          <!-- Footer -->
          <div style="text-align: center; margin-top: 30px; padding-top: 20px; border-top: 2px solid #ddd; color: #555;">
            <p style="font-size: 16px;">Thank you for choosing IMCHESS Academy!</p>
            <p style="font-size: 14px;">For any queries, contact us at <strong>admin@imchessacademy.com</strong></p>
          </div>
        </div>
      `;

        doc.html(invoiceHtml, {
            callback: function (doc) {
                doc.save(`${formData?.name}-invoice.pdf`);
            },
            x: 10,
            y: 10,
            width: 180,
            windowWidth: 800
        });
    };

    const isFormValid = () => {
        const allFieldsFilled = formData.name && formData.email && formData.phone && formData.month;
        const allClassesValid = formData.classes.every(cls => cls.classLevel && cls.fees && cls.numClasses);
        return allFieldsFilled && allClassesValid;
    };

    return (
        <div className="p-6 max-w-lg mx-auto bg-white rounded-lg shadow-md">
            <h2 className="text-xl font-bold mb-4">Invoice Form</h2>
            <form>
                {['name', 'email', 'phone', 'date', 'month'].map((field) => (
                    <TextField
                        key={field}
                        fullWidth
                        margin="normal"
                        label={field.charAt(0).toUpperCase() + field.slice(1)}
                        name={field}
                        type={field === "date" ? "date" : "text"}
                        value={formData[field]}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                    />
                ))}
                <Typography variant="h6">Class Details</Typography>
                {formData.classes.map((cls, index) => (
                    <Grid container spacing={2} key={index}>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <InputLabel>Select Level</InputLabel>
                                <Select
                                    value={cls.classLevel}
                                    onChange={(e) => handleClassChange(index, "classLevel", e.target.value)}
                                >
                                    {classLevels.map((level) => (
                                        <MenuItem key={level} value={level}>{level}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                type="number"
                                label="Fees"
                                value={cls.fees}
                                onChange={(e) => handleClassChange(index, "fees", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                type="number"
                                label="Num of Classes"
                                value={cls.numClasses}
                                onChange={(e) => handleClassChange(index, "numClasses", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                label="Total"
                                value={cls.total}
                                InputProps={{ readOnly: true }}
                            />
                        </Grid>
                    </Grid>
                ))}
                <Button variant="contained" color="info" onClick={addClass} sx={{ mt: 2, mr: 2, color: "black" }}>
                    Add Class
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2, mr: 2, color: "blue" }}
                    onClick={() => setShowInvoice(true)}
                    disabled={!isFormValid()}
                >
                    Show Invoice
                </Button>
                <Button
                    variant="contained"
                    color="dark"
                    sx={{ mt: 2, color: "red" }}
                    onClick={() => {
                        setFormData({
                            name: "",
                            email: "",
                            phone: "",
                            date: new Date().toISOString().split("T")[0],
                            month: "",
                            classes: [],
                        });
                        setShowInvoice(false);
                    }}
                >
                    Reset All
                </Button>
            </form>
            {showInvoice && (
                <Paper elevation={2} sx={{ padding: 2, marginTop: 3 }}>
                    <Typography variant="h4">Invoice Preview</Typography>
                    <hr />
                    <Typography><strong>Name:</strong> {formData.name}</Typography>
                    <Typography><strong>Email:</strong> {formData.email}</Typography>
                    <Typography><strong>Phone:</strong> {formData.phone}</Typography>
                    <Typography><strong>Date:</strong> {formData.date}</Typography>
                    <Typography><strong>Month:</strong> {formData.month}</Typography>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="invoice table">
                            <TableBody>
                                {formData?.classes?.map((cls, index) => (
                                    <TableRow key={index} sx={{ backgroundColor: index % 2 === 0 ? "#f5f5f5" : "white" }}>
                                        <TableCell>Level : {cls?.classLevel}</TableCell>
                                        <TableCell>Fees : {cls?.fees}</TableCell>
                                        <TableCell>No. of classes : {cls?.numClasses}</TableCell>
                                        <TableCell>Total : {cls?.total}</TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell colSpan={3} sx={{ fontWeight: "bold" }}>Grand Total: {calculateGrandTotal()}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Button variant="contained" color="inherit" sx={{ mt: 2, color: "blue" }} onClick={generatePDF}>
                        Download PDF
                    </Button>
                </Paper>
            )}
        </div>
    );
};

export default InvoiceGenerator;